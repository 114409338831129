import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthForm from "../../Components/Auth_Form/Auth_Form";
import useFetch from "../../utils/useFetch";
import { toast } from "react-toastify";


const AuthPage = () => {
  const navigate = useNavigate();
  const [userCredentials, setUserCredentials] = useState({});
  const { apiData, error, loading } = useFetch(
    "/api/v1/user/login",
    "POST",
    userCredentials,
    true
  );

  const handleSubmit = (data) => {
    setUserCredentials(data);
  };

  useEffect(() => {
    if (apiData && apiData.statusCode === 201) {
      if (apiData.data === "Admin Inframantra") {
        toast.success(`${apiData.message}`);
        localStorage.setItem("username", apiData.data);
        navigate("/dashboard");
      } else if(apiData.data === 'event.Admin'){
        toast.success(`${apiData.message}`);
        navigate("/event-dashboard");
        localStorage.setItem("username", apiData.data);
      }else{
        toast.success(`${apiData.message}`);
        navigate("/form-selection");
        localStorage.setItem("username", apiData.data);
      }
    } else if (apiData && apiData.statusCode === 400) {
      toast.error(`${apiData.message}`);
    }
  }, [apiData, navigate, loading]);

  localStorage.clear();

  return (
    <div>
      <AuthForm onSubmit={handleSubmit} />
    </div>
  );
};

export default AuthPage;
