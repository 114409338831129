import React from "react";
import { useNavigate } from "react-router-dom";
import './UI.css';

const LogoutButton = () => {
    const navigate = useNavigate();

    const logout = () => {
        // Redirect to login page (replace with your actual logic)
          navigate('/');
      }
    return (
      <div className="logout-container-dash">
        <div id="logout-container-dash">
          <button id="logout-button-dash" onClick={logout}>
            Logout
          </button>
        </div>
      </div>
    );
}

export default LogoutButton;