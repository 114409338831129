import React from "react";
import { IoMdClose } from "react-icons/io";
import { MdOutlineDashboard } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { FaUser } from "react-icons/fa";
import { FaRegHandshake } from "react-icons/fa";

const SideBar = ({isSidebarOpen, toggleSidebar, activeMenuItem, setActiveMenuItem}) =>{

    const handleMenuItemClick = (menuItem) => {
        setActiveMenuItem(menuItem);
      };

    return(
        <div className={`left_sidebar ${isSidebarOpen ? "open" : ""}`}>
        <button className='close_btn' onClick={toggleSidebar}>
          {" "}
          <IoMdClose />
        </button>
        <div className='logo '>
          <h2>
            <img src='/assets/infra-logo-b.png' alt='' />
          </h2>
        </div>
        <div className='main_content'>
          <div className='menu_items'>
            <div
              className={`menu_item ${
                activeMenuItem === "dashboard" && "active"
              }`}
              onClick={() => handleMenuItemClick("dashboard")}
            >
              <MdOutlineDashboard />
              <p>Dashboard</p>
            </div>
            <div
              className={`menu_item ${
                activeMenuItem === "calendar" && "active"
              }`}
              onClick={() => handleMenuItemClick("calendar")}
            >
              <SlCalender />
              <p>Calendar Vatika</p>
            </div>
            <div
              className={`menu_item ${
                activeMenuItem === "sovCalendar" && "active"
              }`}
              onClick={() => handleMenuItemClick("sovCalendar")}
            >
              <SlCalender />
              <p>Calendar Sovereign</p>
            </div>
            <div
              className={`menu_item ${
                activeMenuItem === "Channel-Partner" && "active"
              }`}
              onClick={() => handleMenuItemClick("Channel-Partner")}
            > 
            <FaRegHandshake/>
              <p>CP Date</p>
            </div>
            <div
              className={`menu_item ${
                activeMenuItem === "User" && "active"
              }`}
              onClick={() => handleMenuItemClick("Users")}
            > 
            <FaUser/>
              <p>Sales List</p>
            </div>
          </div>
        </div>
      </div>
    )
}

export default SideBar