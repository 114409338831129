import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Form_Selection_Box.css";

function FormSelectionBox({ boxName, imageUrl, navigateLink }) {
  const navigate = useNavigate();

  const boxStyle = {
    backgroundImage: `url(${imageUrl})`,
  };


  const handleFormSelectionBoxClick = () => {
    navigate(`${navigateLink}`);
  };

  return (
    <div onClick={handleFormSelectionBoxClick} className='box' >
      <img src={`${imageUrl}`} alt="" />
      <h2>{boxName}</h2>
    </div> 
  );
}

export default FormSelectionBox;
