import React from "react";
import * as XLSX from "xlsx";
import "./utils.css";

const ExportToExcel = ({ data }) => {
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    // Prepare data for the sheet
    const customers = data.map((customer) => ({
      Name: customer.name,
      "Phone Number": customer.phoneNumber,
      Email: customer.email,
      Designation: customer.designation,
      "Visit Count": customer.visitCount,
    }));

    // Create sheet and append to workbook
    const customersSheet = XLSX.utils.json_to_sheet(customers);
    XLSX.utils.book_append_sheet(wb, customersSheet, "Customers");

    // Generate binary string
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Convert binary string to ArrayBuffer
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    // Create Blob and trigger download
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "customers.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div>
      <button className='jsonToExcelBtn' onClick={exportToExcel}>
        Export to Excel
      </button>
    </div>
  );
};

export default ExportToExcel;
