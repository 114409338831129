import React, { useState, useEffect } from "react";
import "./utils.css";
const SearchInputComponent = ({ data = [], selectedValue }) => {
  const [searchData, setSearchData] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [value, setValue] = useState("")


  const handleInputSearch = (e) => {
    const inputValue = e.target.value;
    setSearchData(inputValue);
    setValue(inputValue); // Update value state with the input value
  };
  const handleSelectedValue = (val) => {
    selectedValue(val)
    setValue(val)
    setFilteredData([])
  }
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (data.length > 0 && searchData.length > 0) {
        let filteredValues = data.filter((item) =>
          item.toLowerCase().includes(searchData.toLowerCase())
        );
        setFilteredData(filteredValues);
      } else {
        setFilteredData([]);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [searchData, data]);

  return (
    <>
      <input  type="text" value={value}  onChange={handleInputSearch} />
      <div className="selection">
      {filteredData.map((val, index) => (
        <p key={index} onClick={()=> handleSelectedValue(val)}>{val}</p>
      ))}
      </div>
    </>
  );
};

export default SearchInputComponent;
