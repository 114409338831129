import React from "react";
import { IoMdClose } from "react-icons/io";
import { MdOutlineDashboard } from "react-icons/md";


const SideBar = ({isSidebarOpen, toggleSidebar, activeMenuItem, setActiveMenuItem}) =>{

    const handleMenuItemClick = (menuItem) => {
        setActiveMenuItem(menuItem);
      };

    return(
        <div className={`left_sidebar ${isSidebarOpen ? "open" : ""}`}>
        <button className='close_btn' onClick={toggleSidebar}>
          {" "}
          <IoMdClose />
        </button>
        <div className='logo '>
          <h2>
            <img src='/assets/infra-logo-b.png' alt='' />
          </h2>
        </div>
        <div className='main_content'>
          <div className='menu_items'>
            <div
              className={`menu_item ${
                activeMenuItem === "dashboard" && "active"
              }`}
              onClick={() => handleMenuItemClick("dashboard")}
            >
              <MdOutlineDashboard />
              <p>Dashboard</p>
            </div>
          </div>
        </div>
      </div>
    )
}

export default SideBar