import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Customer_Form.css";
import { toast } from "react-toastify";
import SearchInputComponent from "../../utils/SearchBar";
import RequiredField from "../RequiredField/RequiredField";

import { MdOutlineCancel } from "react-icons/md";


const CustomerForm = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [salesData, setSalesData] = useState('');

  useEffect(() => {
    // Fetch data from API when component mounts
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/sales",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      const { data, message } = responseData;
      if (!response.ok) {
        throw new Error(`${message}`);
      }
      setSalesData(data[0].names);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSalesData([]);
      toast.error(`Error: ${error.message}`);
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    lastFiveDigits: "",
    fullPhoneNumber: "",
    email: "",
    address: "",
    city: "",
    pincode: "",
    occupationType: "",
    designation: "",
    companyName: "",
    meetingWith: "",
    placeOfVisit: "",
    channelPartnerName: "",
    cpEnrolled: "",
    cpPhoneNumber: "",
    cpCompanyName: "",
    cpName: "",
    priceRange: "",
    sizeRange: "",
    remarks: [""], // Updated to be an array
    date: getCurrentDate(), // Set default value to current date
  });

  const [remarkInput, setRemarkInput] = useState("");

  // Function to get the current date in the format YYYY-MM-DD
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const channelPartner = localStorage.getItem("channel-partner");

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "remarks") {
      const updatedRemarks = formData.remarks.map((remark, idx) =>
        idx === index ? value : remark
      );
      setFormData((prevData) => ({
        ...prevData,
        remarks: updatedRemarks,
      }));
    } else {
      // Prevent selecting future dates
      if (name === "date") {
        const selectedDate = new Date(value);
        const currentDate = new Date();

        if (selectedDate > currentDate) {
          // Reset the value to today's date
          setFormData((prevData) => ({
            ...prevData,
            date: getCurrentDate(),
          }));
          return; // Exit the function to prevent further execution
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const clearForm = () => {
    setFormData({
      name: "",
      phoneNumber: "",
      remarks: [],
      state: "",
      pincode: "",
      occupationType: "",
      designation: "",
      companyName: "",
      meetingWith: "",
      placeOfVisit: "",
    });
  };

  const [cpCompanyName, setCpCompanyName] = useState("");
  const [selectedCpName, setSelectedCpName] = useState("");
  const [meetingWith, setMeetingWith] = useState("");

  // Handle the selected value for cpCompanyName
  const handleCpCompanyName = (val) => {
    // Update state with the processed data
    setSelectedCpName(val);
  };

  // Handle the selected value for meetingWith
  const handleMeetingWith = (val) => {
    const str = val;
    const parts = str.split("-"); // Split the string into parts using the dash
    const result = parts[0]; // Concatenate the first part with " XYZ-" and the second part
    setMeetingWith(result);
  };

  const userName = localStorage.getItem("username");

  const handleCompanyName = async () => {
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/allData/company-names",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json", // Provide headers as an object
          },
        }
      );
      const responseData = await response.json();
      const { data, message } = responseData;
      // console.log(data);
      if (!response.ok) {
        throw new Error(`${message}`);
      }
      // Extracting the last part of each company name
      setCpCompanyName(() => data); // Update state with fetched data using a callback
      if (response.ok) {
        // toast.success(`${message}`);
      } else {
        toast.error(`${message}`);
      }
    } catch (error) {
      setCpCompanyName("");
      toast.error(`Error: ${error.message}`);
    }
  };

  const addRemarkField = () => {
    setFormData((prevData) => ({
      ...prevData,
      remarks: [...prevData.remarks, ""],
    }));
  };

  const removeRemark = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      remarks: prevData.remarks.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if the "Other" option is selected
    if (formData.sizeRange === "other" && formData.otherSize.trim() === "") {
      toast.error("Please provide the size in the 'Other' field");
      return;
    }
    let apiUrl = "";
    let method = "";
    let requestBody = "";
    if (channelPartner) {
      apiUrl =
        "https://siteregistration.inframantra.com/api/v1/channel-partner/customer";
      method = "POST";
      requestBody = {
        name: formData.name,
        phoneNumber: formData.fullPhoneNumber,
        placeOfVisit: formData.placeOfVisit,
        channelPartnerPhoneNumber: formData.fullPhoneNumber,
        lastFiveDigits: formData.phoneNumber,
        meetingWith: formData.meetingWith,
      };
    } else {
      apiUrl =
        "https://siteregistration.inframantra.com/api/v1/customer/register";
        // "https://siteregistration.inframantra.com/api/v1/customer/register"
      method = "POST";
      requestBody = {
        name: formData.name,
        phoneNumber: formData.phoneNumber ? formData.phoneNumber : "",
        lastFiveDigit: formData.lastFiveDigits,
        remarks: formData.remarks, // Updated to send array of remarks
        city: formData.city,
        meetingWith: meetingWith,
        placeOfVisit: formData.placeOfVisit,
        cpEnrolled: selectedCpName,
        budget: formData.priceRange,
        requirement:
          formData.sizeRange === "other"
            ? formData.otherSize
            : formData.sizeRange,
        date: formData.date,
        createdBy: userName,
      };
    }
    try {
      document.querySelector(".spinner").classList.add("show");
      // console.log("Requested Body Data>>>:>>>> ",requestBody);

      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      // console.log("Response Data",responseData);
      if (response.ok) {
        toast.success(`${responseData.message}`);
        setTimeout(() => {
          navigate("/form-selection");
          document.querySelector(".spinner").classList.remove("show");
        }, 2000);
        clearForm();
      } else {
        // Handle error response
        toast.error(`${responseData.message}`);
        document.querySelector(".spinner").classList.remove("show");
      }
    } catch (error) {
      console.error("Error sending form data:", error.message);
      document.querySelector(".spinner").classList.remove("show");
    }
  };

  return (
    <div className="customerFormWrapper">
      {channelPartner ? (
        <div className="section-head">
          <h2>Add Customer Details</h2>
        </div>
      ) : (
        <div className="section-head">
          <h2>Customer Details</h2>
        </div>
      )}
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <>
            <div>
              <label>
                {" "}
                <RequiredField />
                Name:
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label>
                <RequiredField />
                CP EnRolled
              </label>
              <select
                name="cpEnrolled"
                value={formData.cpEnrolled}
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.value === "Yes") {
                    handleCompanyName();
                  }
                }}
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
            {formData.cpEnrolled === "Yes" && (
              <div>
                <div>
                  <label>CP Company Name</label>
                  <SearchInputComponent
                    data={cpCompanyName}
                    selectedValue={(val) =>
                      handleCpCompanyName(val, "cpCompanyName")
                    }
                  />
                </div>
              </div>
            )}
            {formData.cpEnrolled === "Yes" ? (
              ""
            ) : (
              <div>
                <label>
                  <RequiredField /> Phone No:
                </label>
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  maxLength={10}
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
            {formData.cpEnrolled === "Yes" && (
              <div>
                <RequiredField/>
                <label>Last Five Digits:</label>
                <input
                  type="tel"
                  name="lastFiveDigits"
                  minLength={5}
                  maxLength={10}
                  autoComplete="off"
                  value={formData.lastFiveDigits}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
            <div>
              <label>Requirement</label>
              <select
                name="sizeRange"
                value={formData.sizeRange}
                onChange={handleChange}
              >
                <option value="">Select Your Requirement (BHK)</option>
                <option value="1 BHK">1 BHK</option>
                <option value="2 BHK">2 BHK</option>
                <option value="3 BHK">3 BHK</option>
                <option value="4 BHK">4 BHK</option>
                <option value="other">Others</option>
              </select>
            </div>
            {formData.sizeRange === "other" && (
              <div>
                <label>Other Size</label>
                <input
                  type="text"
                  name="otherSize"
                  value={formData.otherSize}
                  onChange={handleChange}
                />
              </div>
            )}
            <div>
              <label className="title">Budget</label>
              <select
                name="priceRange"
                value={formData.priceRange}
                onChange={handleChange}
              >
                <option value="">Please Select A Price</option>
                <option value="Less than 1Cr*">Less than 1Cr*</option>
                <option value="1Cr-1.5Cr">Rs. 1Cr* - 1.5 Cr*</option>
                <option value="1.5Cr-2cr">Rs. 1.5 C* - 2Cr*</option>
                <option value="2cr*-2.5Cr*">Rs. 2 Cr* - 2.5 Cr*</option>
                <option value="more than 2Cr*">More Than 2.5 Cr*</option>
              </select>
            </div>
            <div>
              <label>Remarks:</label>
              {formData.remarks.map((remark, index) => (
                <div key={index} className="remarks">
                  <input
                    type="text"
                    name="remarks"
                    value={remark}
                    onChange={(e) => handleChange(e, index)}
                    autoComplete="off"
                    maxLength={100}
                  />
                  <div className="remove-button" type="button" onClick={() => removeRemark(index)}>
                    <MdOutlineCancel/>
                  </div>
                </div>
              ))}
              <button type="button" className="add-remark" onClick={addRemarkField}>Add Remark</button>
            </div>
            <div>
              <label>Meeting With:</label>
              <SearchInputComponent
                name="meetingWith"
                data={salesData}
                selectedValue={(val) => handleMeetingWith(val)}
              />
            </div>
            <div>
              <label>
                Place of Visit:
              </label>
              <select
                name="placeOfVisit"
                value={formData.placeOfVisit}
                onChange={handleChange}
                // required
              >
                <option value="">Select Place of Visit</option>
                <option value="Inframantra HO">Inframantra HO</option>
                <option value="Vatika Seven Elements">
                  Vatika Seven Elements
                </option>
                <option value="Sovereign Park">Sovereign Park</option>
              </select>
            </div>
            <div>
              <label>
                Date:
              </label>
              <input
                type="date"
                name="date"
                id=""
                required
                value={formData.date}
                onChange={handleChange}
                max={getCurrentDate()} // Set the max attribute to today's date
              />
            </div>
          </>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default CustomerForm;
