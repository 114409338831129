import React, { useState, useEffect } from "react";
import ExportToExcelCPDate from "../../../utils/jsonToExcelCPDate";
import { toast } from "react-toastify";
import "./channelpartner.css";

const ChannelPartner = ({ customerwithCP, customerwithoutCP }) => {
  const renderCustomerTable = (customerData, title) => {
    return (
      <>
        <div>
          <h2>{title}</h2>
          <table className='data-table'>
            <>
              {Object.keys(customerData)
                .sort()
                .map((date) => (
                  <React.Fragment key={date}>
                    <thead
                      className={
                        title === "Channel Partners with Customers"
                          ? "channel-partner"
                          : "customer"
                      }
                    >
                      <tr>
                        <th>{new Date(date).toLocaleDateString()}</th>
                        <th>
                          {title === "Channel Partners with Customers"
                            ? "Customer"
                            : "Name"}
                        </th>
                        {title === "Channel Partners with Customers" && (
                          <th>Channel Partner</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {customerData[date].map((item, index) => (
                        <tr key={`${date}-${index}`}>
                          <td>{index + 1}</td>
                          <td>{item.name || item}</td>{" "}
                          {/* Adjusted to display name or string */}
                          {title === "Channel Partners with Customers" && (
                            <td>{item.cpEnrolled || "Not Enrolled"}</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </React.Fragment>
                ))}
            </>
          </table>
        </div>
      </>
    );
  };

  const renderCustomerTables = (customerDataWithCP, customerDataWithoutCP) => {
    return (
      <div className='cpdate-container'>
        <div className='table-wrapper-container'>
          <div className='table-wrapper'>
            {renderCustomerTable(
              customerDataWithCP,
              "Channel Partners with Customers"
            )}
          </div>
          <div className='table-wrapper'>
            {renderCustomerTable(
              customerDataWithoutCP,
              "Channel Partner without Customers"
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='con'>
      {/* Render tables */}
      <div className='table-container'>
        {renderCustomerTables(customerwithCP, customerwithoutCP)}
      </div>
    </div>
  );
};

export default ChannelPartner;
