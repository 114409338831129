import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../Customer_Form/Customer_Form.css";
import { toast } from "react-toastify";
import RequiredField from "../RequiredField/RequiredField";

import SearchInputComponent from "../../utils/SearchBar.jsx";


const ChannelPartnerForm = () => {
  const location = useLocation();
  const [meetingWith, setMeetingWith] = useState("")
  const [formData, setFormData] = useState({
    companyName: "",
    meetingWith: "",
    placeOfVisit: "",
    phoneNumber: "",
    date: getCurrentDate(), // Set default value to current date
  });
  const [salesData, setSalesData] = useState('');
  useEffect(() => {
    // Fetch data from API when component mounts
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/sales",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      const { data, message } = responseData;
      if (!response.ok) {
        throw new Error(`${message}`);
      }
      setSalesData(data[0].names);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSalesData([]);
      toast.error(`Error: ${error.message}`);
    }
  };

  // Function to get the current date in the format YYYY-MM-DD
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
   
  
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Prevent selecting future dates
    if (name === "date") {
      const selectedDate = new Date(value);
      const currentDate = new Date();

      if (selectedDate > currentDate) {
        // Reset the value to today's date
        setFormData((prevData) => ({
          ...prevData,
          date: getCurrentDate(),
        }));
        return; // Exit the function to prevent further execution
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
   // Handle the selected value for meetingWith
   const handleMeetingWith = (val) => {
    const str = val;
    const parts = str.split("-"); // Split the string into parts using the dash
     const result = parts[0]; // Concatenate the first part with " XYZ-" and the second part
    setMeetingWith(result);
  };

  const userName = localStorage.getItem("username")

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      document.querySelector(".spinner").classList.add("show");
      const requestBody ={
        name: formData.name,
        companyName: formData.companyName,
        meetingWith: meetingWith,
        placeOfVisit: formData.placeOfVisit,
        phoneNumber: formData.phoneNumber,
        date: formData.date,
        createdBy: userName
      }
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/channel-partner/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
  

      const responseData = await response.json();

      if (response.ok) {
        // Handle successful response
        // console.log("Form data sent successfully");

        toast.success(`${responseData.message}`);
        setTimeout(() => {
          navigate("/form-selection", {
            state: { AutheticatedPhoneno: formData.phoneNumber },
          });
          // console.log(formData.phoneNumber);
          document.querySelector(".spinner").classList.remove("show");
        }, 2000);
        clearForm();
      } else {
        // Handle error response
        toast.error(`${responseData.message}`);
        document.querySelector(".spinner").classList.remove("show");
      }
    } catch (error) {
      console.error("Error sending form data:", error.message);
      document.querySelector(".spinner").classList.remove("show");
    }
    // console.log("Form data:", formData);
    // Clear form fields after submission
  };

  const clearForm = () => {
    setFormData({
      companyName: "",
      meetingWith: "",
      placeOfVisit: "",
      phoneNumber: "",
    });
  };

  return (
    <div className="customerFormWrapper">
      <div className="section-head">
        <h2>Channel Partner's Detail</h2>
      </div>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div>
            <label>
              <RequiredField />
              Channel Partner Name:
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </div>
          <div>
            <label>
              <RequiredField /> Phone No:
            </label>
            <input
              type="tel"
              value={formData.phoneNumber}
              onChange={handleChange}
              pattern="[0-9]{5,10}"
              autoComplete="off"
              maxLength={10}
              name="phoneNumber"
            />
          </div>
          <div>
            <label>
              <RequiredField />
              Channel Partner Company Name:
            </label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </div>
          <div>
            <label>
              {/* <RequiredField /> */}
              Meeting With:
            </label>
            <SearchInputComponent
              name="meetingWith"
              data={salesData}
              selectedValue={(val) => handleMeetingWith(val)}
            />
          </div>
          <div>
            <label>
              <RequiredField />
              Place of Visit:
            </label>
            <select
              name="placeOfVisit"
              value={formData.placeOfVisit}
              onChange={handleChange}
              required
            >
              <option value="">Select Place of Visit</option>
              <option value="Inframantra HO">Inframantra HO</option>
              <option value="Vatika Seven Elements">
                Vatika Seven Elements
              </option>
              <option value="Vatika Sovereign Park">
                 Vatika Sovereign Park
              </option>
            </select>
          </div>
          <div>
            <label>Date:</label>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              required
              autoComplete="off"
              max={getCurrentDate()} // Set the max attribute to today's date
            />
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default ChannelPartnerForm;
