import React from "react";
import './UI.css'


const Spinner = () => {
    return (
      <div className="spinner">
        <div className="spinner-container">
          <div className="triple-spinner"></div>
        </div>
      </div>
    );
}

export default Spinner;