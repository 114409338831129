import React from "react";
import * as XLSX from "xlsx";

const ExportToExcelCPDate = ({ customerwithCP, customerwithoutCP }) => {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    // Customers with CP Sheet
    const customersWithCP = [];
    Object.entries(customerwithCP).forEach(([date, customers]) => {
      customers.forEach((customer) => {
        customersWithCP.push({
          Date: date,
          Name: customer.name,
          "CP Enrolled": customer.cpEnrolled,
        });
      });
    });
    const customersWithCPSheet = XLSX.utils.json_to_sheet(customersWithCP);
    XLSX.utils.book_append_sheet(wb, customersWithCPSheet, "Customers with CP");

    // Channel Partners without Customers Sheet
    const channelPartnersWithoutCustomers = [];
    Object.entries(customerwithoutCP).forEach(([date, partners]) => {
      partners.forEach((partner) => {
        channelPartnersWithoutCustomers.push({
          Date: date,
          "Channel Partner": partner,
        });
      });
    });
    const channelPartnersWithoutCustomersSheet = XLSX.utils.json_to_sheet(
      channelPartnersWithoutCustomers
    );
    XLSX.utils.book_append_sheet(
      wb,
      channelPartnersWithoutCustomersSheet,
      "CP without Customers"
    );

    XLSX.writeFile(wb, `Data_${currentDate}.xlsx`);
  };

  return (
    <div>
      <button className='jsonToExcelBtn' onClick={exportToExcel}>
        Export to Excel
      </button>
    </div>
  );
};

export default ExportToExcelCPDate;
