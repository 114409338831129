import { useState, useEffect } from "react";

function useFetch(actionurl, method, requestData = {}, loader = {}) {
  const [apiData, setApiData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const apiUrl =
    process.env.API_URL ||
    "https://siteregistration.inframantra.com";
  const url = apiUrl + actionurl;

  //  console.log(url);
  const handleApiFetch = async (loader) => {
    // if(loader === true){document.querySelector(".spinner".classList.add('show'))}
    if (Object.keys(requestData).length > 0) {
      setLoading(true);
      try {
        const response = await fetch(url, {
          method: method,
          mode: "cors",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          setLoading(false);
          const responseData = await response.json();
          setApiData(responseData);
        } else {
          const errorData = await response.json();
          setApiData(errorData);
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    handleApiFetch();
  }, [url, requestData]);

  useEffect(() => {
    const spinner = document.querySelector(".spinner");
    if (spinner) {
      if (loading && loader) {
        spinner.classList.add("show");
        setApiData(null);
      } else {
        spinner.classList.remove("show");
        setApiData(null);
      }
    }
  }, [loading, loader]);

  return { apiData, error, loading };
}

export default useFetch;
