// TablePopup.js

const TablePopup = ({ data, setShowPopup, setRowSelect }) => {
  // Function to format date
  const formatDate = (date) => {
    // Your date formatting logic here
    const dateObject = new Date(date);
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const year = dateObject.getFullYear();
    return `${day < 10 ? "0" : ""}${day} / ${
      month < 10 ? "0" : ""
    }${month} / ${year}`;
  };
  const date = formatDate(data.createdAt);
  const closePopup = () => {
    setShowPopup(false); // Hide popup
    setRowSelect(false);
  };
  // console.log(data);
  return (
    <div className='popup'>
          <div className='popup-content'>
            <h2>{data.name}</h2>
            <br />
            {/* <p>Phone: {data.phoneNumber}</p> */}
            {data.phoneNumber.length > 10 ? <p>LastFiveDigit: {data.lastFiveDigit}</p> : <p>Phone: {data.phoneNumber}</p>}
            {!data.email ? "" : <p>Email: {data.email}</p>}
            <p>Meeting With: {data.meetingWith}</p>
            <p>Place Of Visit: {data.placeOfVisit}</p>
            <p>Date : {date}</p>
            {data.budget && data.budget.length > 0 ? (
              <p>Budget: {data.budget}</p>
            ) : (<p>{""}</p>)}
            {data.visitCount && data.visitCount ? (
              <p>Visit Count: {data.visitCount}</p>
            ): (<p>{""}</p>)}
            {data.requirement && data.requirement.length > 0 ? (
              <p>Requirement: {data.requirement}</p>
            ) : (<p>{""}</p>)}
            {data.pincode && data.pincode.length > 0 ? (
              <p>Pincode: {data.pincode}</p>
            ) : (<p>{""}</p>)}
            {data.createdBy && data.createdBy.length > 0 ? (<p> Created By: {data.createdBy}</p>) : ("")}
            {data.address && data.address.length > 0 ? (
              <p>Address: {data.address}</p>
            ) : (<p>{""}</p>)}
            {data.cpEnrolled && data.cpEnrolled.length > 0 ? (
              <p>Channel Partner: {data.cpEnrolled}</p>
            ) : (<p>{""}</p>)}
            {data.companyName && data.companyName.length > 0 ? (<p>Company Name: {data.companyName}</p>) : ("")}
            {!data.customerNames ? (
              " "
            ) : (
              <div>
                Customer Names:{" "}
                <ol>
                  {data.customerNames.map((customerName, idx) => (
                    <li
                      key={idx}
                      style={{ marginBottom: "15px" }}
                    >{`${customerName} \n`}</li>
                  ))}
                </ol>
              </div>
            )}
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
  );
};
export default TablePopup;
