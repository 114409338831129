import React from "react";
import * as XLSX from "xlsx";
import { writeFile } from "sheetjs-style";
import "./utils.css";

const ExportToExcel = ({ data }) => {
  const date = new Date();
  // console.log("data from Excel", data);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    // Customers Sheet
    const customers = data.customer.map((customer) => ({
      Name: customer.name,
      "Phone Number": customer.phoneNumber,
      Remarks: customer.remarks.map(remark => `• ${remark}`).join('\r\n'), // Join remarks with bullet points and newline characters
      City: customer.city,
      "Meeting With": customer.meetingWith,
      "Place of Visit": customer.placeOfVisit,
      "CP Enrolled": customer.cpEnrolled,
      Requirement: customer.requirement,
      Budget: customer.budget,
      "Arrived On": customer.createdAt.substring(0, 10),
      "Visit Count": customer.visitCount
    }));

    const customersSheet = XLSX.utils.json_to_sheet(customers);

    // Apply style to wrap text for the Remarks column
    const wrapTextStyle = {
      alignment: {
        wrapText: true,
        vertical: 'top'
      }
    };

    // Iterate over all cells in the Remarks column
    for (let R = 0; R < customers.length + 1; R++) {
      const cellAddress = XLSX.utils.encode_cell({ c: 2, r: R }); // 2 is the index of Remarks column
      if (!customersSheet[cellAddress]) customersSheet[cellAddress] = { v: '' };
      customersSheet[cellAddress].s = wrapTextStyle;
    }

    XLSX.utils.book_append_sheet(wb, customersSheet, "Customers");

    // Channel Partners Sheet
    const channelPartners = data.channelPartner.map((partner) => ({
      Name: partner.name,
      "Phone Number": partner.phoneNumber,
      "Company Name": partner.companyName,
      "Meeting With": partner.meetingWith,
      "Place of Visit": partner.placeOfVisit,
      "Arrived On": partner.createdAt.substring(0, 10),
    }));

    const channelPartnersSheet = XLSX.utils.json_to_sheet(channelPartners);

    XLSX.utils.book_append_sheet(wb, channelPartnersSheet, "Channel Partners");

    writeFile(wb, `Site Registration Data ${currentDate}.xlsx`);
  };

  return (
    <div>
      <button className='jsonToExcelBtn' onClick={exportToExcel}>
        Export to Excel
      </button>
    </div>
  );
};

export default ExportToExcel;
