import React, { useState, useEffect } from "react";
import "./customer_revisit-form.css";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const CustomerRevisit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [lastFiveDigit, setLastFiveDigit] = useState("");
  const [customers, setCustomers] = useState([]);
  const [customersWithCp, setCustomersWithCp] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [error, setError] = useState("");
  const [isListOpen, setIsListOpen] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "phoneNumber") {
      setPhoneNumber(value);
      setLastFiveDigit(""); // Clear last five digits input when searching by phone number
    } else if (name === "lastFiveDigit") {
      setLastFiveDigit(value);
      setPhoneNumber(""); // Clear phone number input when searching by last five digits
    }
    setSelectedCustomer(null);
    setIsListOpen(true); // Open the list when input changes

    // Fetch customer data when input length is 5 or more
    if (value.length >= 5) {
      handleSearchCustomer();
    } else {
      setCustomers([]);
      setCustomersWithCp([]);
    }
  };

  const handleSearchCustomer = async () => {
    try {
      // Build query parameters based on input
      const queryParams = new URLSearchParams();
      if (phoneNumber) queryParams.append("phoneNumber", phoneNumber);
      if (lastFiveDigit) queryParams.append("lastFiveDigit", lastFiveDigit);

      const response = await fetch(`https://siteregistration.inframantra.com/api/v1/customer/getRevisit1?${queryParams.toString()}`);
      const data = await response.json();

      if (data.error) {
        setError(data.error);
        setCustomers([]);
        setCustomersWithCp([]);
      } else {
        setError("");
        // Split data into customers and customersWithCp if needed
        setCustomers(data.customers || []);
        setCustomersWithCp(data.customersWithCp || []);
      }
    } catch (error) {
      setError("Error searching for customers");
      setCustomers([]);
      setCustomersWithCp([]);
      console.error("Error searching for customers:", error);
    }
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    setIsListOpen(false); // Close the list when customer is selected
    setError(""); // Reset the error message
  };

  const handleUpdateCustomer = async (e) => {
    e.preventDefault();

    if (!selectedCustomer) {
      setError("No customer selected");
      return;
    }

    const { name, phoneNumber } = selectedCustomer;

    if (!name || !phoneNumber) {
      setError("Name and phone number are required");
      return;
    }

    const requestData = {
      name,
      phoneNumber,
    };

    const apiUrl = "https://siteregistration.inframantra.com/api/v1/customer/revisit";
    const method = "PUT";
    document.querySelector(".spinner").classList.add("show");
    try {
      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();

      if (response.ok) {
        toast.success(`${responseData.message}`);
        setTimeout(() => {
          navigate("/form-selection");
          document.querySelector(".spinner").classList.remove("show");
        }, 2000);
        setSelectedCustomer(null);
        setPhoneNumber("");
        setLastFiveDigit("");
      } else {
        toast.error(`${responseData.message}`);
        document.querySelector(".spinner").classList.remove("show");
        setError(responseData.message);
      }
    } catch (error) {
      toast.error(`${error.message}`);
      document.querySelector(".spinner").classList.remove("show");
      setError("Error updating customer: " + error.message);
    }
  };

  return (
    <div className="customer-revisit-form">
      <div>
        <h2>Customer Check</h2>
      </div>
      <div className="customer-revisit-checker">
        <div className="customer-input-check">
          <div className="customer-input-field">
            <input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              placeholder="Enter First Five Digits"
              value={phoneNumber}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="lastFiveDigit"
              id="lastFiveDigit"
              placeholder="Enter Last Five Digits"
              value={lastFiveDigit}
              onChange={handleInputChange}
            />
          </div>
          {isListOpen && (customers.length > 0 || customersWithCp.length > 0) ? (
            <div className="selection-check">
              <ul>
                {customers.slice(0, 20).map((customer, index) => (
                  <li
                    key={index}
                    onClick={() => handleCustomerSelect(customer)}
                  >
                    {customer.name} - {customer.phoneNumber}
                  </li>
                ))}
                {customersWithCp.map((customer, index) => (
                  <li
                    key={index}
                    onClick={() => handleCustomerSelect(customer)}
                  >
                    {customer.name} - {new Date(customer.date).toLocaleDateString("en-GB", { day: "numeric", month: "numeric" })} - {customer.cpEnrolled.split(" -- ")[1]}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
          {error && <p className="error-message">{error}</p>}
        </div>
      </div>
      {(lastFiveDigit || phoneNumber) && 
        <div className="customer-revisit-update">
          <div className="customer-input-update">
            <input
              type="text"
              name="name"
              id="name"
              value={selectedCustomer ? selectedCustomer.name : ""}
              placeholder="Name of the Customer"
              onChange={(e) =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  name: e.target.value,
                })
              }
              disabled={!selectedCustomer}
            />
            <input
              type="text"
              name={phoneNumber ? "phoneNumber" : "cpEnrolled"}
              id={phoneNumber ? "phoneNumber" : "cpEnrolled"}
              value={selectedCustomer ? (phoneNumber ? selectedCustomer.phoneNumber : selectedCustomer.cpEnrolled) : ""}
              placeholder={phoneNumber ? "Phone Number of the Customer" : "CP Enrolled"}
              onChange={(e) =>
                setSelectedCustomer({
                  ...selectedCustomer,
                  [phoneNumber ? "phoneNumber" : "cpEnrolled"]: e.target.value,
                })
              }
              disabled={!selectedCustomer}
            />
            <button onClick={handleUpdateCustomer}>Update Visit</button>
          </div>
        </div>
      }
    </div>
  );
};

export default CustomerRevisit;
