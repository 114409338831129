// AuthForm.js
import React, { useEffect, useState } from "react";
import "./Auth_Form.css";
import { FaEye } from "react-icons/fa"; // Import the CSS file
import { FaEyeSlash } from "react-icons/fa";
import RequiredField from "../RequiredField/RequiredField";

const AuthForm = ({ onSubmit }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ username, password });
    clearForm();
  };
  const clearForm = () => {
    setPassword("");
    setUsername("");
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevIsPasswordVisible) => !prevIsPasswordVisible);
  };

  return (
    <div className='container eq'>
      <div className='card info col'>
        <div className='logo animated'>
          <img
            style={{ width: "250px" }}
            src='/assets/infra-logo-b.png'
            alt=''
          />
        </div>
      </div>
      <div className='card col'>
        <h1 className='title animated'>Login</h1>
        <form onSubmit={handleSubmit}>
          <div className='auth-input-container animated'>
            <label>Username</label>
            <input
              type='test'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required={true}
              placeholder='email@example.com'
            />
            <div className='bar'></div>
          </div>
          <div className='auth-input-container animated'>
            <label>Password</label>
            <input
              type={isPasswordVisible ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
              placeholder='Please Enter your password'
            />
            <div className='password-icon' onClick={togglePasswordVisibility}>
              {isPasswordVisible ? (
                <i data-feather='eye'>
                  <FaEye />
                </i>
              ) : (
                <i data-feather='eye-off'>
                  <FaEyeSlash />
                </i>
              )}
            </div>
            <div className='bar'></div>
          </div>
          <div className='button-container1 animated'>
            <button type='submit'>Proceed to Login</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthForm;