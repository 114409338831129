// EventDashboard.js
import React, { useEffect, useState } from 'react';
import LeftSide from './sidebar';
import { GiHamburgerMenu } from "react-icons/gi";
import { CiSearch } from "react-icons/ci";
import { toast } from 'react-toastify';
import './eventDashboard.css';
import LogoutButton from '../../Components/UI/logOut';
import ExportToExcel from '../../utils/jsonExcel';

const EventDashboard = () => {
  const [isSelected, setIsSelected] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedData, setSortedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const DashboardData = async () => {
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/event/get",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      const { data, message } = responseData;
      if (!response.ok) {
        throw new Error(`${message}`);
      }
      setSortedData(data); // Update state with fetched data
      toast.success(message);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSortedData([]); // Clear sorted data on error
      toast.error(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    DashboardData();
  }, []);

  useEffect(() => {
    console.log("Sorted Data updated:", sortedData);
    setFilteredData(
      sortedData.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [sortedData, searchQuery]);

  return (
    <div className="dashboard-container dash-container">
      <LeftSide
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        activeMenuItem={activeMenuItem}
        setActiveMenuItem={setActiveMenuItem}
      />
      <div className='main_content'>
       <LogoutButton className='dash-logout' />
        <div className='left_right_sidebar_opener'>
          <div className='hamburger' onClick={toggleSidebar}>
            <GiHamburgerMenu />
          </div>
        </div>
        <div className='menu_item_name_and_filter '>
          <div className='main_navbar'>
            <div className='search_box'>
              <i className='bx bx-search-alt-2'></i>{" "}
              <input
                type='text '
                placeholder='Search'
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
              <div className='dark_mode_icon'>
                <CiSearch />
              </div>
            </div>
          </div>
        </div>
        <div className='table'>
          <div className='tabs'>
            <div className='three_dots'>
              <i className='bx bx-dots-vertical-rounded'><ExportToExcel data={filteredData}/></i>
            </div>{" "}
          </div>
          <table>
            <thead>
              <tr>
                <th>Sr No:</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Created By</th>
                <th>Designation</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.phoneNumber}</td>
                  <td>{item.email}</td>
                  <td>{item.createdBy}</td>
                  <td>{item.designation}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EventDashboard;
