import { useEffect, useState } from "react";
import React from "react";
import "./dashboard.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { CiSearch } from "react-icons/ci";
import { toast } from "react-toastify";
import ExportToExcel from "../../utils/jsonToExcel";
import TablePop from "./TablePopup";
import LeftSideBar from "./SideBar";
import CalenderData from "./CalenderData";
import CalenderDataSov from './CalendarSov'
import DashBoardData from "./DashRender";
import SalesData from "./SalesData";
import ChannelPartnerbyDate from "./channelpartner/channelPartner";
import LogoutButton from "../../Components/UI/logOut";
import ExportToExcelCPDate from "../../utils/jsonToExcelCPDate";

const Dashboard = () => {
  const [isSelected, setIsSelected] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("dashboard");
  const [activeTab, setActiveTab] = useState("customer"); // State variable to track active tab
  const [sortedData, setSortedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Add state for filtered data
  const [searchQuery, setSearchQuery] = useState("");
  const [sovData, setSovData] = useState([]);

  const [dashData, setDashData] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split("T")[0], // Set default start date to current date
    endDate: new Date().toISOString().split("T")[0], // Set default end date to current date
  });
  const [sovDateRange, setSovDateRange] = useState({
    startDate: new Date().toISOString().split("T")[0], // Set default start date to current date
    endDate: new Date().toISOString().split("T")[0], // Set default end date to current date
  });
  const [channelPartnerDateRange, setChannelPartnerDateRange] = useState({
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });
  // Function to handle start date change for ChannelPartnerbyDate component
  const handleSovDateChange = (date) => {
    setSovDateRange((prevRange) => ({
      ...prevRange,
      startDate: date,
    }));
  };
  // Function to handle end date change for ChannelPartnerbyDate component
  const handleSovEndDateChange = (date) => {
    // Update the end date for ChannelPartnerbyDate component
    setSovDateRange((prevRange) => ({
      ...prevRange,
      endDate: date,
    }));
  };
  const handleChannelPartnerStartDateChange = (date) => {
    // Update the start date for ChannelPartnerbyDate component
    setChannelPartnerDateRange((prevRange) => ({
      ...prevRange,
      startDate: date,
    }));
  };
  // Function to handle end date change for ChannelPartnerbyDate component
  const handleChannelPartnerEndDateChange = (date) => {
    // Update the end date for ChannelPartnerbyDate component
    setChannelPartnerDateRange((prevRange) => ({
      ...prevRange,
      endDate: date,
    }));
  };
  const [selectedRow, setSelectedRow] = useState(null); // State variable to store selected row data
  const [showPopup, setShowPopup] = useState(false); // State variable to control popup visibility
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State variable to track sidebar visibility
  const [rowSelect, setRowSelect] = useState("");
  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // JSX for sidebar content
  // Function to handle click on table row
  const handleRowClick = (rowData, index) => {
    setSelectedRow(rowData); // Set selected row data
    setSelectedRow(rowData === isSelected ? null : rowData);
    setShowPopup(true); // Show popup
    setIsSelected(true);
    setRowSelect(index);
  };
  // useEffect to fetch data from the API when the component mount
  const DashboardData = async () => {
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/allData/get-latest",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json", // Provide headers as an object
          },
        }
      );
      const responseData = await response.json();
      const { data, message } = responseData;
      if (!response.ok) {
        throw new Error(`${message}`);
      }
      setSortedData(() => data); // Update state with fetched data using a callback
      if (response.ok) {
        toast.success(`${message}`);
        // console.log("Sorted data", sortedData);
      } else {
        toast.error(`${message}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setFilteredData("");
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleStartDateChange = (date) => {
    if (date) {
      // If a date is selected, update the start date
      setDateRange((prevRange) => ({
        ...prevRange,
        startDate: date,
      }));
    } else {
      // If no date is selected, keep the start date as empty string
      setDateRange((prevRange) => ({
        ...prevRange,
        startDate: "Please select a date",
      }));
    }
  };
  const handleEndDateChange = (date) => {
    if (date) {
      // If a date is selected, update the start date
      setDateRange((prevRange) => ({
        ...prevRange,
        endDate: date,
      }));
    } else {
      // If no date is selected, keep the start date as empty string
      setDateRange((prevRange) => ({
        ...prevRange,
        endDate: "26/12/2024",
      }));
    }
  };
  // Data sorting of the dashboard Data
  useEffect(() => {
    if (sortedData.customer && sortedData.customer.length > 0) {
      const filteredCustomer = sortedData.customer.filter(
        (item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.phoneNumber.includes(searchQuery)
      );
      const filteredChannelPartner = sortedData.channelPartner.filter(
        (item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.phoneNumber.includes(searchQuery) ||
          (item.companyName &&
            item.companyName.toLowerCase().includes(searchQuery.toLowerCase()))
      );
      setDashData({
        customer: filteredCustomer,
        channelPartner: filteredChannelPartner,
      });
    } else {
      setDashData({ customer: [], channelPartner: [] }); // Set filtered data to empty arrays if sortedData is empty
    }
  }, [searchQuery, sortedData]);
  // Sorted data
  useEffect(() => {
    setSortedData(dashData);
  }, []);
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    DashboardData();
  }, []); // Add an empty dependency array to ensure the effect runs only once when the component mounts
  const handleDateSubmit = async () => {
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/allData/date",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: {
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
            },
          }),
        }
      );
      const responseData = await response.json();
      const { data, message } = responseData;
      if (!response.ok) {
        throw new Error(
          `${message} for ${new Date(
            dateRange.startDate
          ).toLocaleDateString()} & ${new Date(
            dateRange.endDate
          ).toLocaleDateString()}`
        );
      }
      setFilteredData(() => data); // Update state with fetched data using a callback
      if (response.ok) {
        toast.success(
          `${message} for ${new Date(
            dateRange.startDate
          ).toLocaleDateString()} & ${new Date(
            dateRange.endDate
          ).toLocaleDateString()}`
        );
      } else {
        toast.error(
          `${message} for ${new Date(
            dateRange.startDate
          ).toLocaleDateString()} & ${new Date(
            dateRange.endDate
          ).toLocaleDateString()}`
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setFilteredData("");
      toast.error(`Error: ${error.message}`);
    }
  };
  const handleCPDateSubmit = async () => {
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/allData/date",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: {
              startDate: channelPartnerDateRange.startDate,
              endDate: channelPartnerDateRange.endDate,
            },
          }),
        }
      );
      const responseData = await response.json();
      const { data, message } = responseData;
      groupDataByDate(data);
      if (!response.ok) {
        throw new Error(
          `${message} for ${new Date(
            dateRange.startDate
          ).toLocaleDateString()} & ${new Date(
            dateRange.endDate
          ).toLocaleDateString()}`
        );
      }
      setFilteredData(() => data); // Update state with fetched data using a callback
      if (response.ok) {
        toast.success(
          `${message} for ${new Date(
            dateRange.startDate
          ).toLocaleDateString()} & ${new Date(
            dateRange.endDate
          ).toLocaleDateString()}`
        );
      } else {
        toast.error(
          `${message} for ${new Date(
            dateRange.startDate
          ).toLocaleDateString()} & ${new Date(
            dateRange.endDate
          ).toLocaleDateString()}`
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setFilteredData("");
      toast.error(`Error: ${error.message}`);
    }
  };
  // Sovereign Park Calendar Data
  const handleSovDateSubmit = async () => {
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/allData/dateSov",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            date: {
              startDate: sovDateRange.startDate,
              endDate: sovDateRange.endDate,
            },
          }),
        }
      );
      const responseData = await response.json();
      const { data, message } = responseData;
      if (!response.ok) {
        throw new Error(
          `${message} for ${new Date(
            dateRange.startDate
          ).toLocaleDateString()} & ${new Date(
            dateRange.endDate
          ).toLocaleDateString()}`
        );
      }
      setSovData(() => data); // Update state with fetched data using a callback
      if (response.ok) {
        toast.success(
          `${message} for ${new Date(
            dateRange.startDate
          ).toLocaleDateString()} & ${new Date(
            dateRange.endDate
          ).toLocaleDateString()}`
        );
      } else {
        toast.error(
          `${message} for ${new Date(
            dateRange.startDate
          ).toLocaleDateString()} & ${new Date(
            dateRange.endDate
          ).toLocaleDateString()}`
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setSovData("");
      toast.error(`Error: ${error.message}`);
    }
  };
  // Render customer data if active tab is "customer", otherwise render channel partner data
  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the active tab when clicked
    let tabNameCP = document.getElementById("tab_name_cp");
    let tabNameCustomer = document.getElementById("tab_name_customer");
    if (tab === "customer") {
      tabNameCP.style.color = "";
      tabNameCP.style.fontSize = "15px";
      tabNameCP.style.fontWeight = "";
      tabNameCustomer.style.color = "#daa520";
      tabNameCustomer.style.fontSize = "#daa520";
      tabNameCustomer.style.fontWeight = "#daa520";
    } else {
      tabNameCP.style.color = "#daa520";
      tabNameCP.style.fontSize = "#daa520";
      tabNameCP.style.fontWeight = "#daa520";
      tabNameCustomer.style.color = "";
      tabNameCustomer.style.fontSize = "15px";
      tabNameCustomer.style.fontWeight = "";
    }
  };
  const [dashTab, setDashTab] = useState("customer");
  const handleDashClick = (tab) => {
    setDashTab(tab);
    let tabNameCP = document.getElementById("tab_name_cp");
    let tabNameCustomer = document.getElementById("tab_name_customer");
    if (tab === "customer") {
      tabNameCP.style.color = "";
      tabNameCP.style.fontSize = "15px";
      tabNameCP.style.fontWeight = "";
      tabNameCustomer.style.color = "#daa520";
      tabNameCustomer.style.fontSize = "#daa520";
      tabNameCustomer.style.fontWeight = "#daa520";
    } else {
      tabNameCP.style.color = "#daa520";
      tabNameCP.style.fontSize = "#daa520";
      tabNameCP.style.fontWeight = "#daa520";
      tabNameCustomer.style.color = "";
      tabNameCustomer.style.fontSize = "15px";
      tabNameCustomer.style.fontWeight = "";
    }
  };
  const [customerwithCP, setCustomerwithCP] = useState([]);
  const [customerwithoutCP, setCustomerwithoutCP] = useState([]);
  const groupDataByDate = (data) => {
    let sortedData = [...data.customer].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    const groupedCustomerData = sortedData.reduce(
      (acc, { name, cpEnrolled, createdAt }) => {
        if (!acc[createdAt]) {
          acc[createdAt] = [];
        }

        acc[createdAt].push({ name, cpEnrolled: cpEnrolled || null });

        return acc;
      },
      {}
    );

    const customerWithCP = {};
    const channelPartnerWithoutCustomers = {};

    for (const [date, customers] of Object.entries(groupedCustomerData)) {
      customerWithCP[date] = customers.filter(
        (customer) => customer.cpEnrolled
      );
    }

    // Sort channel partners by date
    const sortedChannelPartners = [...data.channelPartner].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    for (const channelPartner of sortedChannelPartners) {
      const date = channelPartner.createdAt.split("T")[0]; // Assuming 'createdAt' is a valid ISO date string
      if (!channelPartnerWithoutCustomers[date]) {
        channelPartnerWithoutCustomers[date] = [];
      }

      // Check if the channel partner has no associated customers or customer names
      if (
        channelPartner.customer.length === 0 &&
        channelPartner.customerNames.length === 0
      ) {
        const formattedName = `${channelPartner.companyName} -- ${channelPartner.name}`;
        channelPartnerWithoutCustomers[date].push(formattedName);
      }
    }

    console.log(
      "channelPartnerWithoutCustomers",
      channelPartnerWithoutCustomers
    );

    setCustomerwithCP(customerWithCP);
    setCustomerwithoutCP(channelPartnerWithoutCustomers);
  };

  const renderDashboardContent = () => {
    return (
      <>
        {activeMenuItem === "dashboard" && (
          <div className='table'>
            <div className='tabs'>
              <div className='tab_name'>
                <p
                  id='tab_name_customer'
                  onClick={() => handleDashClick("customer")}
                >
                  Customer
                </p>
                <p
                  id='tab_name_cp'
                  onClick={() => handleDashClick("channelPartner")}
                >
                  Channel Partner
                </p>
              </div>
              <ExportToExcel data={sortedData} />
              <div className='three_dots'>
                <i className='bx bx-dots-vertical-rounded'></i>
              </div>{" "}
            </div>
            <DashBoardData
              dashData={dashData}
              activeTab={dashTab}
              handleRowClick={handleRowClick}
              rowSelect={rowSelect}
            />
          </div>
        )}
        {activeMenuItem === "calendar" && (
          <div className='reservation-box'>
            <div className='top'>
              <div className='static'>
                <div className='input-container' id='date-picker-container'>
                  <label>From</label>
                  <input
                    type='date'
                    id='date-checkin'
                    className='date-field'
                    name={`${dateRange.startDate}`}
                    value={dateRange.startDate}
                    onChange={(e) => handleStartDateChange(e.target.value)}
                  />
                </div>
              </div>
              <div className='flex'>
                <div className='input-container' id='date-picker-container'>
                  <label>To</label>
                  <input
                    type='date'
                    id='date-checkout'
                    className='date-field'
                    name={`${dateRange.endDate}`}
                    value={dateRange.endDate}
                    onChange={(e) => handleEndDateChange(e.target.value)}
                  />
                </div>
                <div className='button-container'>
                  <span className='button ok' onClick={handleDateSubmit}>
                    Fetch Data
                  </span>
                </div>
              </div>
            </div>
            <div className='table'>
              <div className='tabs'>
                <div className='tab_name'>
                  <p
                    id='tab_name_customer'
                    onClick={() => handleTabClick("customer")}
                  >
                    Customer
                  </p>
                  <p
                    id='tab_name_cp'
                    onClick={() => handleTabClick("channelPartner")}
                  >
                    Channel Partner
                  </p>
                  {Object.keys(filteredData).length > 0 && (
                    <ExportToExcel data={filteredData} />
                  )}
                </div>
                <div className='three_dots'>
                  <i className='bx bx-dots-vertical-rounded'></i>
                </div>{" "}
              </div>
              <CalenderData
                dashData={filteredData}
                activeTab={activeTab}
                handleRowClick={handleRowClick}
                rowSelect={rowSelect}
              />
            </div>
          </div>
        )}
        {activeMenuItem === "sovCalendar" && (
          <div className='reservation-box'>
            <div className='top'>
              <div className='static'>
                <div className='input-container' id='date-picker-container'>
                  <label>From</label>
                  <input
                    type='date'
                    id='date-checkin'
                    className='date-field'
                    name={`${sovDateRange.startDate}`}
                    value={sovDateRange.startDate}
                    onChange={(e) => handleSovDateChange(e.target.value)}
                  />
                </div>
              </div>
              <div className='flex'>
                <div className='input-container' id='date-picker-container'>
                  <label>To</label>
                  <input
                    type='date'
                    id='date-checkout'
                    className='date-field'
                    name={`${sovDateRange.endDate}`}
                    value={sovDateRange.endDate}
                    onChange={(e) => handleSovEndDateChange(e.target.value)}
                  />
                </div>
                <div className='button-container'>
                  <span className='button ok' onClick={handleSovDateSubmit}>
                    Fetch Data
                  </span>
                </div>
              </div>
            </div>
            <div className='table'>
              <div className='tabs'>
                <div className='tab_name'>
                  <p
                    id='tab_name_customer'
                    onClick={() => handleTabClick("customer")}
                  >
                    Customer
                  </p>
                  <p
                    id='tab_name_cp'
                    onClick={() => handleTabClick("channelPartner")}
                  >
                    Channel Partner
                  </p>
                  {Object.keys(sovData).length > 0 && (
                    <ExportToExcel data={sovData} />
                  )}
                </div>
                <div className='three_dots'>
                  <i className='bx bx-dots-vertical-rounded'></i>
                </div>{" "}
              </div>
              <CalenderDataSov
                dashData={sovData}
                activeTab={activeTab}
                handleRowClick={handleRowClick}
                rowSelect={rowSelect}
              />
            </div>
          </div>
        )}
        {activeMenuItem === "Users" && (
          <div className='reservation-box'>
            <div className='top'>
              <SalesData />
            </div>
          </div>
        )}
        {activeMenuItem === "Channel-Partner" && (
          <div className='reservation-box'>
            <div className='top'>
              <div className='static'>
                <div className='input-container' id='date-picker-container'>
                  <label>From</label>
                  <input
                    type='date'
                    id='date-checkin'
                    className='date-field'
                    name={`${channelPartnerDateRange.startDate}`}
                    value={channelPartnerDateRange.startDate}
                    onChange={(e) =>
                      handleChannelPartnerStartDateChange(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className='flex'>
                <div className='input-container' id='date-picker-container'>
                  <label>To</label>
                  <input
                    type='date'
                    id='date-checkout'
                    className='date-field'
                    name={`${channelPartnerDateRange.endDate}`}
                    value={channelPartnerDateRange.endDate}
                    onChange={(e) =>
                      handleChannelPartnerEndDateChange(e.target.value)
                    }
                  />
                </div>
                <div className='button-container'>
                  <span className='button ok' onClick={handleCPDateSubmit}>
                    Fetch Data
                  </span>
                </div>
              </div>
            </div>

            <div className='table'>
              <ExportToExcelCPDate
                customerwithCP={customerwithCP}
                customerwithoutCP={customerwithoutCP}
              />
              <ChannelPartnerbyDate
                customerwithCP={customerwithCP}
                customerwithoutCP={customerwithoutCP}
              />
            </div>
          </div>
        )}
      </>
    );
  };
  return (
    <div className='dash-container '>
      <LeftSideBar
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        activeMenuItem={activeMenuItem}
        setActiveMenuItem={setActiveMenuItem}
      />
      <div className='main_content'>
        <LogoutButton className='dash-logout' />
        <div className='left_right_sidebar_opener'>
          <div className='hamburger' onClick={toggleSidebar}>
            <GiHamburgerMenu />
          </div>
        </div>
        <div className='menu_item_name_and_filter '>
          <div className='main_navbar'>
            <div className='search_box'>
              <i className='bx bx-search-alt-2'></i>{" "}
              <input
                type='text '
                placeholder='Search'
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
              <div className='dark_mode_icon'>
                <CiSearch />
              </div>
            </div>
          </div>
        </div>
        {renderDashboardContent()}
        {/* <RenderedData dashData={dashData} sortedData={sortedData}/> */}
        {showPopup && (
          <TablePop
            data={selectedRow}
            setShowPopup={setShowPopup}
            date={selectedRow.date}
            setRowSelect={setRowSelect}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
