import React, { useState } from "react";
import { toast } from "react-toastify";

import "./baby_form.css";

const EventForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    designation: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://siteregistration.inframantra.com/api/v1/event/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const responseData = await response.json();
      console.log(responseData);
      if (response.ok) {
        toast.success(responseData.message);
        setFormData({
          name: "",
          phoneNumber: "",
          email: "",
          designation: "",
        });
      } else {
        toast.error(responseData.message);
      }
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    }
  };

  return (
    <div className="event-form-body">
      <div className="form-wrapper">
        <div className="image-container">
          <img src="/assets/bk-shivani.jpg" alt="Left" className="side-image" />
          <p>BK Shivani</p>
          <p>"Spiritual Guide"</p>
        </div>
        <div className="form-container">
          <form className="event-form" onSubmit={handleSubmit}>
            <h2>Let's Celebrate Your Child's Personality within the Womb</h2>
            <div className="form-group">
              <label htmlFor="name">Name *</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone *</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                maxLength={10}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="designation">Designation/Profession</label>
              <input
                type="text"
                id="designation"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
              />
            </div>
            <button type="submit" className="submit-button">
              Submit
            </button>
          </form>
        </div>
        <div className="image-container">
          <img src="/assets/dr.nitika.jpg" alt="Right" className="side-image" />
          <p>Dr. Nikita Sobti</p>
          <p>"Obstericial & Gynaecologist"</p>
        </div>
      </div>
    </div>
  );
};

export default EventForm;
