import React, { useState, useEffect } from 'react';
import './UserData.css';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { MdDeleteOutline, MdSave, MdClose } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { IoAddOutline } from "react-icons/io5";

const Users = () => {
  const [userRequests, setUserRequests] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false); // State for delete confirmation modal
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [newUserName, setNewUserName] = useState('');

  useEffect(() => {
    // Fetch data from API when component mounts
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/sales",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();
      const { data, message } = responseData;
      if (!response.ok) {
        throw new Error(`${message}`);
      }
      setSalesData(data);
      toast.success(`${message}`);
    } catch (error) {
      console.error("Error fetching data:", error);
      setSalesData([]);
      toast.error(`Error: ${error.message}`);
    }
  };

  const openModal = (userId) => {
    setSelectedUserId(userId);
    setModalIsOpen(true);
    setNewUserName(userId); // Initialize the input field with the selected user's name
  };

  const closeModal = () => {
    setSelectedUserId(null);
    setNewUserName('');
    setModalIsOpen(false);
  };

  const openDeleteModal = (userId) => {
    setSelectedUserId(userId);
    setDeleteModalIsOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  const addUser = async () => {
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/sales/add",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: newUserName }),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || "Failed to add user");
      }
      toast.success(responseData.message || "User added successfully");
      fetchUserData(); // Fetch updated user data
      closeModal(); // Close modal after adding
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const updateUser = async () => {
    try {
      const response = await fetch(
        "https://siteregistration.inframantra.com/api/v1/sales/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ oldName: selectedUserId, newName: newUserName }),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.error || "Failed to update user");
      }
      toast.success(responseData.message || "User updated successfully");
      fetchUserData(); // Fetch updated user data
      closeModal(); // Close modal after updating
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const deleteUser = async () => {
    if (!selectedUserId) return;
    try {
      const response = await fetch(
        `https://siteregistration.inframantra.com/api/v1/sales/delete`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ name: selectedUserId }),
        }
      );
      const responseData = await response.json(); // Parse response JSON
      if (!response.ok) {
        throw new Error(responseData.error || "Failed to delete user");
      }
      toast.success("User deleted successfully");
      fetchUserData(); // Fetch updated user data
      closeModal(); // Close modal after deletion
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const saveUser = async () => {
    if (selectedUserId) {
      // If selectedUserId is present, it means we are editing an existing user
      updateUser(); // Call updateUser function
    } else {
      // If selectedUserId is not present, it means we are adding a new user
      addUser(); // Call addUser function
    }
  };

  const confirmDelete = () => {
    deleteUser(); // Call deleteUser function
    closeDeleteModal();
  };

  return (
    <div className="table">
      <h2>List Of Sales Persons and Team Leaders</h2>
      <div className="add-sales" onClick={() => setModalIsOpen(true)}>
        <p>
          <IoAddOutline className='icon'/> Add New
        </p>
      </div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {salesData.map((salesperson, index) =>
            salesperson.names.map((name, innerIndex) => (
              <tr key={index + "-" + innerIndex}>
                <td>{innerIndex + 1}</td>
                <td>{name}</td>
                <td>
                  <div className="action-icons">
                    <div onClick={() => openModal(name)}>
                      <CiEdit className="edit-icon" />
                    </div>
                    <div onClick={()=> openDeleteModal(name)}>
                      <MdDeleteOutline className="delete-icon" />
                    </div>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <div className={`modal ${modalIsOpen ? 'active' : ''}`}>
        <h2>{selectedUserId ? 'Edit Salesperson' : 'Add New Salesperson'}</h2>
        <input
          type="text"
          value={newUserName}
          onChange={(e) => setNewUserName(e.target.value)}
          placeholder="Enter name"
        />
        <div className="button-group">
          <button className="save-btn" onClick={saveUser}><MdSave /> Save</button>
          <button className="close-btn" onClick={closeModal}><MdClose /> Close</button>
        </div>
      </div>
      {modalIsOpen && (
        <div className="modal-overlay" onClick={closeModal}></div>
      )}
      {deleteModalIsOpen && (
        <div className="delete-modal">
          <h2>Delete User</h2>
          <p>Are you sure you want to delete this user?</p>
          <div className="button-group">
            <button className="delete-btn" onClick={confirmDelete}>Yes</button>
            <button className="close-btn" onClick={closeDeleteModal}>No</button>
          </div>
        </div>
      )}
      {deleteModalIsOpen && (
        <div className="modal-overlay" onClick={closeModal}></div>
      )}

    </div>
  );
};

export default Users;
