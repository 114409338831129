import React from 'react';
import './UI.css'
import { useLocation } from "react-router-dom";

const Brand = () => {
    const location = useLocation();
    return (
      <div>
        {location.pathname !== "/" && (
          <div className="img-container">
            <img src="/assets/infra-logo.png" alt="" />
          </div>
        )}
      </div>
    );
}

export default Brand;